@import url('https://fonts.googleapis.com/css2?family=Noto+Sans:ital,wght@0,100..900;1,100..900&display=swap');

body {
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
}

.fw-600 {
  font-weight: 600;
}

.bg-yellow {
  background-color: #FAB406;
}

.active {
  background-color: #FAB406;
  color: #333;
  border-radius: 8px;
  font-weight: 600;
}

.is-active {
  background-color: #FAB406;
  color: #333;
  border-radius: 8px;
  font-weight: 600;
}

.is-active:hover {
  background-color: #FAB406;
  color: #333;
  border-radius: 8px;
  font-weight: 600;
}

.not-active {
  background: white;
  color: #333;
  font-weight: 600;
}

.cursor-pointer {
  cursor: pointer;
}

.delete-icon {
  color: red;
}

.border-gray {
  border-bottom: solid 1px #ddd;
  background: #ddd;
}

a {
  text-decoration: none;
  color: black;
}

.side-nav {
  height: 100vh;
  /* Set height to 100% of viewport height */
}

.home-page {
  overflow-y: auto;
  /* Enable vertical scrolling */
  padding: 100px 15px 40px 15px;
}

.home-page .accordion {
  padding: 40px 0 20px 0;
  border-bottom: 1px solid #ccc;
}

.accordion-header h4 {
  font-weight: 700;
}

.form-label {
  margin-bottom: .5rem;
  font-size: 15px;
}

form .form-control {
  font-size: 15px;
  box-shadow: none !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

ul {
  text-align: left;
  gap: 15px;
}

ul a {
  padding: 0 10px;
}

/* td{
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
} */

svg path {
  height: 150px;
}

.form-control {
  overflow: hidden;
}

.rb-tabs-header {
  margin-bottom: 20px !important;
}

.logo {
  height: 55px;
  /* margin-left: 20px; */
}

.preview-image {
  height: 50px;
  width: auto;
}

.cream-box {
  background-color: #fff;
  /* Light pink color */
  width: 100%;
  position: fixed;
  /* height: 100%; */
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  font-weight: bold;
  color: #333;
  text-align: center;
  z-index: 999;
}

.btn-main,
.btn-main:hover,
.btn-main:focus {
  background-color: #FAB406 !important;
  border: 2px solid #FAB406 !important;
  color: #333 !important;
}

.sidebar-menu-section .ps-sidebar-container {
  position: fixed;
  top: 100px;
  max-height: 500px;
}

.login-bg {
  background-image: url('../images/login-bg.png');
  background-size: cover;
  background-position: 50%;
}

.login-bg input {
  height: 40px;
  font-size: 15px;
}

.upload-button {
  background-color: #fab406;
  border: 1px solid #b48000;
  border-radius: 10px;
  color: #333;
  font-family: 'Noto Sans', sans-serif;
  font-size: 14px;
  padding: 5px 10px;
  font-weight: 600;
}

.upload-button:hover {
  color: #333;
  border-color: #b48000;
  background: transparent;
}

.btn.common-button {
  background-color: #fab406;
  border: 1px solid #b48000;
  border-radius: 10px;
  color: #333;
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  padding: 10px 25px;
  font-weight: 600;
}

.btn.common-button:hover {
  border-color: #b48000;
  background: transparent;
}

.btn.common-button.back-btn {
  background-color: #808080;
  border-color: #808080;
  color: #fff;
}

.btn.common-button.back-btn:hover {
  color: #808080;
  background-color: transparent;
}

.dropdown-list {
  width: 170px;
  padding: 20px 0;

}

ul.base-Popper-root {
  right: 10px !important;
}

.user-management-table th,
.user-management-table td {
  padding: 13px 7px;
  font-size: 14px;
  vertical-align: middle;

}

table.table.user-management-table {
  border: solid 1px #ddd;
  /* position: relative; */
}

.profile-deatils {
  border: 1px solid #ddd;
  border-radius: 10px;
}

.detail-heading {
  background-color: #ddd;
  border-radius: 10px 10px 0 0;
}

.profile-deatils label {
  font-size: 14px;
}

.profile-deatils input::placeholder,
.profile-deatils textarea::placeholder {
  color: gray;
}

.profile-deatils .form-control,
.profile-deatils textarea {
  color: #333;
  font-size: 15px;
  height: 40px;
}

/* .profile-deatils .form-control:disabled {
  background-color: transparent;
} */

.upload-file-close {
  right: 14px;
  top: -13px;
  opacity: 1;
}

.upload-file-close button {
  padding: 0;
}

.upload-file-close i {
  background: #FF0000;
  border-radius: 50px;
  width: 24px;
  height: 24px;
  color: #fff;
  line-height: 24px;
  font-size: 14px;
}

.error_msg {
  font-weight: 600;
  font-size: 13px;
  color: #dc3545;
}

.auction-category-list.user-management-table th,
.auction-category-list.user-management-table td {
  padding: 10px 18px;
}

.user-management-table tr td .edit {
  color: #05C002;
}

.action-popup {
  position: absolute;
  top: calc(100% + 4px);
  /* Adjust this value as needed */
  left: 0;
  z-index: 1000;
}

.action-container {
  background-color: #ffffff;
  border: 1px solid #cccccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  min-width: 100px;
  position: absolute;
  right: 30px;
  z-index: 9;
  border-radius: 5px;
  font-size: 14px;
}

.action-container>div:hover {
  background: #ececec;
}

.check_list input {
  padding: 0;
  height: initial;
  width: initial;
  margin-bottom: 0;
  display: none;
  cursor: pointer;
}

.check_list label {
  position: relative;
  cursor: pointer;
}

.check_list label:before {
  content: '';
  -webkit-appearance: none;
  border: 1px solid #DDDDDD;
  background: #fff;
  border-radius: 2px;
  padding: 7px;
  display: inline-block;
  position: relative;
  vertical-align: middle;
  cursor: pointer;
  margin-right: 5px;
}

.check_list input:checked+label::before {
  background: #FAB406;
  border-color: #FAB406
}

.check_list input:checked+label:after {
  content: '';
  display: block;
  position: absolute;
  top: 3px;
  left: 5px;
  width: 6px;
  height: 12px;
  border: solid #000;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.action-trigger {
  cursor: pointer;
}

.accordion .accordion-item {
  border: none;
}

.accordion button.accordion-button {
  background: transparent;
  padding: 0;
  box-shadow: unset;
}

.accordion button.accordion-button {
  color: #000 !important;
  opacity: 1;
  font-weight: 600;
  font-size: 24px;
}

.accordion-button:not(.collapsed)::after {
  filter: brightness(0);
}

.accordion-body {
  padding: 17px 0 0
}

/* Adjust the styling as needed */


/* Add questions */

.question-button {
  /* background-color: #fab406;
  border: 1px solid #b48000; */
  border-radius: 10px;
  /* color: #333; */
  font-family: 'Noto Sans', sans-serif;
  font-size: 15px;
  padding: 10px 25px;
  font-weight: 600;
}

.switch-large .form-check-input {
  width: 3rem;
  height: 1.5rem;
}

.switch-large .form-check-input:checked {
  background-color: #fab406;
  border-color: #fab406;
}

/* .switch-large .form-check-input:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
} */

/* multiple emails */
.inpt_body {
  border: 1px solid lightgray;
  border-radius: 5px;
  width: 61.5rem;
  padding: 5px;
}

.input-tag .react-tagsinput-input {
  margin-bottom: 0px;
  margin-top: 0;
  padding: 0;
  width: 100%;
  font-size: 12px;
}

.input-tag .react-tagsinput-tag {
  background-color: #fab406;
  border-radius: 2px;
  border: 1px solid #fab406;
  color: black;
  display: inline-block;
  font-family: sans-serif;
  font-size: 13px;
  font-weight: 400;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 5px;
}

.content-button {
  background-color: #fab406;
  border: 1px solid #b48000;
  border-radius: 10px;
  color: #333;
  font-family: 'Noto Sans', sans-serif;
  font-size: 12px;
  padding: 5px 5px;
  font-weight: 600;
}

.media-data-img-wrap {
  height: 100px;
}

.media-data-img-wrap img {
  width: 100%;
  object-position: top;
}

.spinner {
  position: absolute;
  top: 50%;
  left: 50%;
}

.profile-deatils .form-switch .form-check-input {
  width: 3em;
  height: 1.5em;
  box-shadow: none !important;
}

.profile-deatils .form-switch .form-check-input:checked {
  background-color: #FAB406;
  border-color: #FAB406;
  box-shadow: none !important;
}

.row-per-page-pagination .MuiTablePagination-selectLabel {
  margin-bottom: 0px !important;
}

.auction-item-detail-popup .profile-deatils .form-control,
.auction-item-detail-popup .profile-deatils textarea {
  height: auto;
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.auction-item-detail-popup .profile-deatils .media-data-img-wrap {
  height: auto;
}

.flag-comments-img-wrap img {
  height: 100% !important;
  width: 100% !important;
}

.owner-detail-phone-number-wrap .dropdown .dropdown-toggle {
  border: 1px solid #dee2e6;
  border-right: 0;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
  padding: .375rem 0 .375rem .75rem !important;
  height: 40px;
}

.owner-detail-phone-number-wrap input {
  border-left: 0;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.profile-deatils .owner-detail-phone-number-wrap input {
  border-radius: 5px !important;
}

.owner-detail-phone-number-wrap .dropdown-menu {
  min-height: 300px;
  height: 100%;
  overflow: scroll;
  overflow-x: hidden;
  z-index: 9;
}

.owner-detail-phone-number-wrap .search-icon.search-icon-store {
  display: inline-block;
  top: 50%;
  transform: translateY(-50%);
  left: 10px;
}

.flag-comments-img-wrap {
  height: 60px;
  width: 60px;
}